<template>
  <enyo-card class="card-user">
    <img
      slot="image"
      src="https://ununsplash.imgix.net/photo-1431578500526-4d9613015464?fit=crop&fm=jpg&h=300&q=75&w=400"
      :alt="user.firstName + user.lastName"
    />
    <div class="author">
      <a :href="'/app/users/' + user._id">
        <img
          class="avatar border-gray"
          :src="user.avatar || '/static/img/default-avatar.png'"
          :alt="user.firstName + user.lastName"
        />
        <h4 class="title">
          {{ user.firstName }} {{ user.lastName }}
          <br />
          <small>{{ user.email }}</small>
          <br />
          <small>{{ user.phonenumber }}</small>
        </h4>
      </a>
    </div>
    <section class="text-secondary" v-if="user.city">
      {{ $t('common.field.city') }}:
      <small class="text-muted pull-right">{{ user.city }}</small>
    </section>
    <section class="text-secondary" v-if="user.address">
      {{ $t('common.field.address') }}:
      <small class="text-muted pull-right">{{ user.address }}</small>
    </section>
    <section class="text-secondary" v-if="user.society">
      {{ $t('common.field.society') }}:
      <small class="text-muted pull-right">{{ user.society }}</small>
    </section>
    <section class="text-secondary" v-if="user.postalcode">
      {{ $t('common.field.postalcode') }}:
      <small class="text-muted pull-right">{{ user.postalcode }}</small>
    </section>
    <section class="text-secondary" v-if="user.phonenumber">
      {{ $t('common.field.phonenumber') }}:
      <small class="text-muted pull-right">{{ user.phonenumber }}</small>
    </section>
    <section class="text-secondary" v-if="user.phonenumber">
      Gitlab:
      <small class="text-muted pull-right">{{ user.gitlabId }}</small>
    </section>
    <section class="text-secondary" v-if="user.roles">
      {{ $t('common.field.roles') }}:
      <small
        class="pull-right badge badge-primary text-white ml-1"
        v-for="role of user.roles"
        v-bind:key="role"
        >{{ role }}</small
      >
    </section>
    <div slot="footer" class="text-center">
      <a v-if="user.phonenumber" :href="'tel:' + user.phonenumber" class="btn btn-simple">
        <i class="fa fa-phone fa-2x"></i>
      </a>
      <a v-if="user.phonenumber2" :href="'tel:' + user.phonenumber2" class="btn btn-simple">
        <i class="fa fa-phone fa-2x"></i>
      </a>
      <a v-if="user.skypeId" :href="'skype://' + user.skypeId" class="btn btn-simple">
        <i class="fa fa-skype fa-2x"></i>
      </a>
      <a v-if="user.slackId" :href="'slack://' + user.slackId" class="btn btn-simple">
        <i class="fa fa-slack fa-2x"></i>
      </a>
      <a v-if="user.trelloId" :href="'trello://' + user.trelloId" class="btn btn-simple">
        <i class="fa fa-trello fa-2x"></i>
      </a>
    </div>
  </enyo-card>
</template>
<script>
export default {
  components: {},
  props: {
    user: Object,
  },
  methods: {},
};
</script>
<style></style>
