<template>
  <transition name="fade" mode="out-in">
    <router-view
      :key="key"
      @esailsEntityConfig-item-updated="refresh"
      @esailsEntityConfig-item-created="refresh"
    ></router-view>
  </transition>
</template>
<script>
// import Swal from 'sweetalert2/dist/sweetalert2';

export default {
  computed: {
    key() {
      return this.$route.meta.forceRefresh
        ? Date.now()
        : this.$route.meta.identity || this.$route.name || this.$route.path;
    },
  },
  methods: {
    refresh() {
      this.$store.commit('models', []);

      this.$notify({
        title: this.$t('Reloading configuration'),
        type: 'info',
        toast: true,
      });
      setTimeout(() => {
        document.location.reload();
      }, 3000);
    },
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter,
  .fade-leave-to
    /* .fade-leave-active in <2.1.8 */

 {
  opacity: 0;
}
</style>
