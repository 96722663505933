<template>
  <div class="input-group">
    <wysiwyg :props="fieldOptions" v-model="value"></wysiwyg>
  </div>
</template>

<script>
import { abstractField } from 'vue-aw-components';

export default {
  mixins: [abstractField],
  computed: {
    fieldOptions() {
      return this.schema && this.schema.fieldOptions ? this.schema.fieldOptions : {};
    },
  },
  data() {
    return {
      //
    };
  },
};
</script>

<style scoped>
@import '~vue-wysiwyg/dist/vueWysiwyg.css';
</style>
