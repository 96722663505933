<template>
  <enyo-card>
    <h4 slot="header" class="card-title">{{ $t('app.labels.editProfile') }}</h4>
    <form v-on:submit="updateProfile">
      <div class="row">
        <div class="col-md-6">
          <fg-input
            type="text"
            :label="$t('common.field.firstName')"
            :placeholder="$t('common.field.firstName')"
            v-model="user.firstName"
          ></fg-input>
        </div>
        <div class="col-md-6">
          <fg-input
            type="text"
            :label="$t('common.field.lastName')"
            :placeholder="$t('common.field.lastName')"
            v-model="user.lastName"
          ></fg-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <fg-input
            :disabled="true"
            type="email"
            :label="$t('common.field.email')"
            :placeholder="$t('common.field.email')"
            v-model="user.email"
          ></fg-input>
        </div>
        <div class="col-md-6">
          <fg-input
            :disabled="true"
            type="email"
            :label="$t('common.field.username')"
            :placeholder="$t('common.field.username')"
            v-model="user.email"
          ></fg-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <fg-input
            type="text"
            :label="$t('common.field.address')"
            :placeholder="$t('common.field.address')"
            v-model="user.address"
          ></fg-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <fg-input
            type="text"
            :label="$t('common.field.city')"
            :placeholder="$t('common.field.city')"
            v-model="user.city"
          ></fg-input>
        </div>
        <div class="col-md-6">
          <fg-input
            type="number"
            :label="$t('common.field.postalcode')"
            :placeholder="$t('common.field.postalcode')"
            v-model="user.postalcode"
          ></fg-input>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-6">
          <fg-input
            type="text"
            :label="$t('common.field.society')"
            :placeholder="$t('common.field.society')"
            v-model="user.society"
          ></fg-input>
        </div>
        <!--
        <div class="col-md-6">
          <label class="control-label">{{ $t('common.field.language') }}</label>
          <multiselect
            :placeholder="$t('common.field.language')"
            v-model="user.locale"
            :multiple="false"
            :options="['fr', 'en']"
          ></multiselect>
        </div>
      --></div>
      <div class="row">
        <div class="col-md-6">
          <fg-input
            type="text"
            :label="$t('common.field.phonenumber')"
            :placeholder="$t('common.field.phonenumber')"
            v-model="user.phonenumber"
          ></fg-input>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label class="control-label">{{ $t('common.field.roles') }}</label>
            <multiselect
              :placeholder="$t('common.field.roles')"
              v-model="user.roles"
              :multiple="true"
              :options="roles"
              :disabled="
                intersection($store.state.user.roles, ['TEAMLEAD', 'ADMIN', 'SUPERADMIN']).length <
                  1
              "
            ></multiselect>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <fg-input
            type="password"
            :label="$t('common.field.new.password')"
            :placeholder="$t('common.placeholders.password')"
            v-model="user.password"
          ></fg-input>
        </div>
        <div class="col-md-6">
          <fg-input
            type="password"
            :label="$t('common.field.confirmPassword')"
            :placeholder="$t('common.placeholders.confirmPassword')"
            v-model="user.confirmPassword"
          ></fg-input>
        </div>
      </div>
      <div class="text-center">
        <button type="submit" class="btn btn-info btn-fill float-right">
          {{ $t('common.buttons.save') }}
        </button>
      </div>
      <div class="clearfix"></div>
    </form>
  </enyo-card>
</template>
<script>
import { apiErrorsMixin } from 'vue-aw-components';
import Multiselect from 'vue-multiselect';
import _ from 'lodash';

export default {
  components: {
    Multiselect,
  },
  props: {
    user: Object,
  },
  data() {
    return {};
  },
  computed: {
    roles() {
      if (!this.$store.state.model.models || this.$store.state.model.models.length < 1) {
        console.warn('Models store is empty');
      }
      const userModel = this.$store.state.model.models.find(m => m.identity === 'user');
      if (!userModel) {
        throw new Error('user_model_not_found');
      }
      const roles = _.get(userModel, 'schema.properties.roles.items.enum');
      if (roles) {
        return roles;
      }
      return [];
    },
  },

  mixins: [apiErrorsMixin],
  created() {},

  methods: {
    intersection: _.intersection,
    startCase: _.startCase,

    updateProfile(e) {
      e.preventDefault();
      this.$emit('update', this.user);
    },
  },
};
</script>
<style></style>
