const regions = [
  {
    code: 'auvergne-rhone-alpes',
    label: 'Auvergne-Rhônes-Alpes',
  },
  {
    code: 'bourgogne-franche-comté',
    label: 'Bourgogne-Franche-Comté',
  },
  {
    code: 'bretagne',
    label: 'Bretagne',
  },
  {
    code: 'centre-val-de-loire',
    label: 'Centre-Val de Loire',
  },
  {
    code: 'corse',
    label: 'Corse',
  },
  {
    code: 'grand-est',
    label: 'Grand Est',
  },
  {
    code: 'hauts-de-france',
    label: 'Hauts-de-France',
  },
  {
    code: 'ile-de-france',
    label: 'Île-de-France',
  },
  {
    code: 'normandie',
    label: 'Normandie',
  },
  {
    code: 'nouvelle-aquitaine',
    label: 'Nouvelle-Aquitaine',
  },
  {
    code: 'occitanie',
    label: 'Occitanie',
  },
  {
    code: 'pays-de-la-loire',
    label: 'Pays de la Loire',
  },
  {
    code: 'provence-alpes-cote-d-azur',
    label: 'Provence-Alpes-Côte d’Azur',
  },
];

const listOfValues = {
  state: {
    users: [],
    providers: [],
    regions,
  },

  getters: {
  },
  mutations: {
    lov_users(state, list) {
      state.users = list;
    },
    regions(state) {
      state.regions = regions;
    },
  },
  actions: {
    refreshListOfValues({
      commit,
    }) {
      return Promise.resolve();
      // const q1 = this._vm.$http.get('/values');
      // q1.then((res) => {
      //   commit('lov_users', res.data.users);
      //   commit('roles', res.data.roles);
      //   commit('extendedRoles');
      //   commit('regions');
      // }).catch((err) => {
      //   console.error(err);
      // });

      // return Promise.all([q1]);
    },
  },
};

export default listOfValues;
