<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <a class="navbar-brand" @click="goToDashboard()" href="#">{{
        $t('common.labels.dashboard')
      }}</a>
      <template v-for="match in $route.matched">
        <template v-if="match.name">
          <router-link
            :key="match.name"
            class="navbar-brand"
            :to="match.path"
            v-if="!match.path.includes(':')"
          >
            <i class="fa fa-caret-right"></i>
            {{
              $route.name == match.name ? $route.params.id || match.name : match.name || 'er'
            }}</router-link
          >
          <span :key="match.name" v-else
            ><i class="fa fa-caret-right"></i>
            {{
              $route.name == match.name ? $route.params.id || match.name : match.name || 'er'
            }}</span
          >
        </template>
      </template>
      <button
        type="button"
        class="navbar-toggler navbar-toggler-right"
        :class="{ toggled: $sidebar.showSidebar }"
        aria-controls="navigation-index"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="toggleSidebar"
      >
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end">
        <ul class="nav navbar-nav mr-auto">
          <li class="nav-item">
            <a class="nav-link" href="#" data-toggle="dropdown"> </a>
          </li>
        </ul>

        <ul class="navbar-nav ml-auto">
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fa fa-bell"></i>
              <span class="notification">{{ notifications.length }}</span>
            </a>
            <div class="dropdown-menu dropdown-menu-right p-0" aria-labelledby="navbarDropdown">
              <a
                class="dropdown-item"
                v-for="notification in notifications"
                :key="notification.id"
                :href="notification.url || '#'"
                >{{ notification.title }}</a
              >
              <div class="dropdown-divider"></div>
              <a
                class="dropdown-item text-center text-danger"
                href="#"
                @click.prevent="clearNotifications()"
                >clear</a
              >
            </div>
          </li>
          <li class="nav-item">
            <router-link
              :to="{ path: '/app/profile' }"
              class="nav-link text-primary user-details"
              v-if="$store.state.user.id"
              >{{ $store.state.user.firstName + ' ' + $store.state.user.lastName }}</router-link
            >
            <!-- <a
              class="nav-link text-danger user-details"
              href="#"
              v-if="$store.state.user._id"
            >{{ $store.state.user.firstName + ' ' + $store.state.user.lastName }}</a>-->
          </li>
          <li class="nav-item">
            <a href="javascript:void(0);" id="logout" class="logout nav-link" @click="logout()">
              <i class="nc-icon nc-button-power"></i>
              <span style="margin-left: 5px;">{{ $t('common.labels.logout') }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
  },
  data() {
    return {
      activeNotifications: false,
      notifications: [],
      notifHandle: null,
    };
  },
  mounted() {
    this.getNotifications();
    this.notifHandle = setInterval(() => {
      this.getNotifications();
    }, 30000);
  },
  beforeDestroy() {
    clearInterval(this.notifHandle);
  },
  methods: {
    async getNotifications() {
      const { data } = await this.$http.get(
        `/api/notification?filters[isRead]=0&filters[userId]=${this.$store.state.user.id}`,
      );
      this.notifications = data.body;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/login');
    },
    goToDashboard() {
      this.$router.push('/');
    },

    clearNotifications() {
      const promises = this.notifications.map(n =>
        this.$http.put(`/api/notification/${n.id}`, { isRead: true }),
      ); // eslint-disable-line
      Promise.all(promises)
        .then(() => this.getNotifications())
        .catch(console.warn);
    },
  },
};
</script>
<style></style>
