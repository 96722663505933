import { Datetime } from 'vue-datetime';
import wysiwyg from 'vue-wysiwyg';
import fgInput from './components/UIComponents/Inputs/formGroupInput.vue';
import UploadButton from './components/UIComponents/Inputs/UploadButton.vue';
import Stats from './components/UIComponents/Stats.vue';
import FieldTextEditor from './components/UIComponents/FieldTextEditor.vue';
import FieldKeyValueObject from './components/UIComponents/FieldKeyValueObject.vue';
import FieldTinyMce from './components/UIComponents/FieldTinyMce.vue';
import FieldKeyEditorObject from './components/UIComponents/FieldKeyEditorObject.vue';

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component('fg-input', fgInput);
    Vue.component('upload-button', UploadButton);
    Vue.component('stats', Stats);
    Vue.component('datetime', Datetime);
    Vue.component('fieldTextEditor', FieldTextEditor);
    Vue.component('fieldKeyValueObject', FieldKeyValueObject);
    Vue.component('fieldTinyMce', FieldTinyMce);
    Vue.component('fieldKeyEditorObject', FieldKeyEditorObject);

    Vue.use(wysiwyg, {
      locale: 'fr'
    });
  },
};

export default GlobalComponents;
