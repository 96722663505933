import { startCase, merge, cloneDeep } from 'lodash';
import localModels from '@/config/models';

const modelDefaultOptions = {
  mode: 'remote',
  url: null,
  columns: null,
  viewPath: null,
  stats: false,
  nestedDisplayMode: 'list', // list | object
  detailPageMode: 'page'
};

const modelDefaultActions = {
  noActions: false,
  create: true,
  edit: true,
  filter: true,
  dateFilter: false,
  view: true,
  delete: true,
  import: false,
  export: false,
};

const modelStore = {
  state: {
    models: []
  },

  getters: {
  },

  mutations: {
    lov_users(state, list) {
      state.users = list;
    },
    models(state, appModels) {
      state.models = appModels;
    },

  },
  actions: {
    getModels({ commit }) {
      const promise = this._vm.$http.get('/api/axel-admin/models');
      promise
        .then(res => {
          const apiModels = res.data.body.map(model => {
            model.title = model.title || model.pageTitle || startCase(model.name);
            const localModel = localModels.find(m => m.identity === model.identity);
            model = {
              ...model,
              options: merge({}, cloneDeep(modelDefaultOptions), model.options),
              actions: merge({}, cloneDeep(modelDefaultActions), model.actions),
              nestedDisplayMode: model.nestedDisplayMode || 'object',
            };
            if (model.identity === 'user') {
              console.log('localModel', localModel);
            }
            model = merge({}, model, localModel);
            return model;
          });
          commit('models', apiModels);
        })
        .catch(err => {
          console.error(err);
        });
      return promise;
    },

  },
};

export default modelStore;
